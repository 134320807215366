:root {
  --black: #07080a;
  --lightblack: #222326;
  --darkgray: #3b3d45;
  --gray: #4e5267;
  --brown: #432f3a;
  --smoke: #798885;
  --blue: #5752af;
  --red: #b02d4a;
  --white: #dbdcdf;
  --yellow: #413e38;

  --grey: #41455a;
  --grey1: #4c5166;
  --grey2: #778196;

  --blue1: #414a72;
  --orange: #ff5e57;
}
@font-face {
  font-family: "coco";
  src: url("./Assets/CocoGothicHeavy.ttf") format("truetype");
}
body {
  margin: 0;
  font-family: "Staatliches", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 1px;
  background-color: #f7f7f7;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.layoutwrapper {
  flex-grow: 1;
  display: flex;
}

.scrollp::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
  background-color: var(--blue1);
}

.scrollp::-webkit-scrollbar {
  width: 12px;
}

.scrollp::-webkit-scrollbar-thumb {
  background-color: var(--orange);
  border: 2px solid transparent;
}
.scrollp::-webkit-scrollbar-thumb :hover {
  background-color: var(--white);
}

::-moz-selection {
  /* Code for Firefox */
  color: white;
  background: var(--yellow);
}

::selection {
  color: white;
  background: var(--yellow);
}
